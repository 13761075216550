@import "styles/variables";
@import "styles/mixings";



.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.container {

    .row:first-child {
        background-color: #006FAC;
        justify-content: space-between;
        height: 53px;
        align-items: center;
        padding-left: 7px;

        .row:nth-child(2) {
            flex: 5;
            justify-content: space-evenly;

            div {
                flex: 1;
                font-weight: bold;
                height: 53px;
                display: flex;
                align-items: center;
                justify-content: center;
        
                a {
                    text-decoration: none;
                    font-weight: 400;
                    font-family: 'Poppins';
                    font-size: 14px;
                    color: white; 
                    text-transform: uppercase;
                }
            }

            .active {
                background-color: #00639FFA;
            }
        }
    }

    > .row:nth-child(2) {
        background-color: #00639F;
        justify-content: center;
        height: 42px;
        align-items: center;

        > div {
            display: flex;
            align-items: center;

            > svg {
                margin-left: 5px;
            }
        }

        > div:first-child {
            margin-right: 20px;
        }
    }

    a {
        text-decoration: none;
        font-weight: 400;
        font-family: 'Poppins';
        font-size: 14px;
        color: white; 
        text-transform: uppercase;
    }
}

.searchToggle {
    height: 41px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    svg {
      height: 25px;
      fill: $white;
    }
  
    @include media-breakpoint-up(md) {
      display: none;
    }
  }