@use "sass:math";

@import "styles/variables";
@import "styles/mixings";

.searchToggle {
  height: 41px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 25px;
    fill: $white;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}


.box {
  background: $primary-blue;
  // height: 46px;
  z-index: 12;


  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div {
      display: flex;
      align-items: center;
      // height: 46px;

      // @include media-breakpoint-down(lg) {
      //   height: 46px;
      // }
    }
  }

  .burger {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      display: none;
    }

    svg {
      fill: $white;
      height: 20px;
    }

    &:hover {
      svg {
        fill: $secondary-alt;
      }
    }
  }

  .logo svg {
    height: 26px;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    bottom: 100%;
    opacity: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    background: white;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: bottom 0.2s ease, opacity 0.2s ease;

    &.open {
      opacity: 1;
      bottom: 0;

      >div {
        display: flex;
        align-items: center;
        height: 51px;

        @include media-breakpoint-down(md) {
          height: 46px;
        }
      }

      svg {
        fill: $secondary-alt;
      }
    }

    .head {
      height: 60px;
      width: 100%;

      >div {
        display: flex;
        justify-content: flex-end;
      }
    }

    .links {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      a {
        line-height: $px-base * 96;
        font-size: $px-base * 32;
        text-decoration: none;
        text-transform: uppercase;
        color: $secondary-alt;

        &:hover {
          text-decoration: underline;
        }
      }

      .current {
        font-weight: 600;
        color: $primary-alt;
      }
    }
  }

  .desktopMenu {
    margin-left: math.div($size-base * 60, 16);
    height: 100%;
    align-items: center;
    display: flex;

    @include media-breakpoint-down(md) {
      display: none;
    }

    > a {
      height: 43px;
    }

    a {
      padding: 0 math.div($size-base * 24, 16);
      font-size: math.div($size-base * 16, 16);
      text-decoration: none;
      text-transform: uppercase;
      color: $white;
      display: flex;
      align-items: center;
      margin-right: 5px;
      line-height: 1.1;

      &:hover {
        background: $primary-hover;
      }
    }

    .current {
      font-weight: 600;
      background: $primary-hover;
    }
  }

  .langPicker {
    justify-self: flex-end;
    border-radius: 5px;
    @include transparentBackgroundColorMixin(0.1, $ghost-white);

    @include media-breakpoint-down(xl) {
      margin-right: 12px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:hover {
      background: $primary-hover;
    }
  }

  .search {
    justify-self: flex-end;
    padding: 8px 15px 8px 0;

    form {
      height: 29px;
      display: flex;

      input,
      button {
        border: none;
        height: 29px;
        line-height: 29px;
        appearance: none;
        background: none;

        svg {
          height: math.div($size-base * 20, 16);
        }
      }

      input {
        padding: 0 $size-base;
        width: 422px;

        @include media-breakpoint-down(xxl) {
          width: auto;
        }

        &::placeholder {
          color: #fff;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;

        svg {
          fill: $white;
        }

        &:hover {
          svg {
            fill: $primary;
          }
        }
      }

      @include transparentBackgroundColorMixin(0.1, $ghost-white);
      border-radius: 5px;
    }

    @include media-breakpoint-down(md) {
      display: none;

      form {
        border: none;
      }

      form button {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    // height: 46px;

    &.searchOpen {
      background: $primary;
      color: white;

      form {
        height: auto;
        padding-left: 27px;
      }

      > :global(.container) {
        >div:first-child {
          display: none;
        }

        >div {
          flex: 1;

          .search {
            flex: 1;

            input {
              color: $white;
              border-bottom: 1px solid #c4c4c4;
              width: 100%;
              height: 30px;

              &::placeholder {
                color: $white;
              }
            }
          }
        }

        svg {
          fill: $white;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .desktopMenu {
      margin-left: $size-base * 2;

      a {
        font-size: $px-base * 16;
        padding: 0 $size-base * 0.5;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .logo {
      margin-left: 10px;
    }
  }
}

.dropdownActivator:hover {
  position: relative;
  width:  146px;
  .dropdown {
    display: block;
  }
}

.dropdown {
  background-color: #00639FFA;
  display: none;
  z-index: 999;
  position: absolute;
  width: 146px;
  top: 45px;
  left: 0;
  .container {
    margin: 21px;

    .row {
      margin: 14px 0 0 0;

      &:first-child, &:nth-child(2) {
        a {
          text-transform: uppercase;
        }
      }

      a {
        text-transform: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.summerMobileMenu {
  width: 100%;
  display: flex;
  justify-content: space-around;

  a {
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: white;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.secondRowContainer {
  background-color: #00639FFA;
  padding: 13px 0;
  display: flex;
  justify-content: center!important;

  a {
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: white;
    margin-right: 20px;
  }
}

.dropdownWrapper {
  position: relative;
  display: flex;
  height: 45px;

  &:hover {
    background-color: #00639FFA;
    width: 146px;

    .dropdown {
      display: block;
    }
  }
}

.dropdownActivator {
  padding-left: 20px !important;
  display: block;
}

.dropdown {
  background-color: #00639FFA;
  display: none;
  z-index: 999;
  position: absolute;
  width: 146px;
  top: 30px;
  left: 0;
  .container {
    margin: 21px;

    .row {
      display: flex;
      align-items: center;
      margin: 14px 0 0 0;

      > svg {
        margin-left: 5px;
      }

      &:first-child, &:nth-child(2) {
        a {
          text-transform: uppercase;
        }
      }

      a {
        text-transform: none;
        margin: 0;
        padding: 0;

        &:hover {
          color: #33B0D9;
          transition: .22s;
        }
      }
    }


    > hr {
      background-color: white;
    }
  }
}
